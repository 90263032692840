<script setup lang="ts">
import { PropType } from 'vue'
import { ContentType } from '@/models/content'

const props = defineProps({
  type: {
    type: String as PropType<ContentType>,
    required: true,
  },
  iconGray: {
    type: Boolean,
    default: false,
  },
})

const isVideo = computed(() => props.type === 'video')
const isArticle = computed(() => props.type === 'article')
const isChart = computed(() => props.type === 'chart')
const isPresentation = computed(() => props.type === 'presentation')
const isMagazine = computed(() => props.type === 'magazine')
</script>

<template>
  <div>
    <VideoBadge v-if="isVideo" :icon-gray="props.iconGray" />
    <ArticleBadge v-if="isArticle" :icon-gray="props.iconGray" />
    <ChartBadge v-if="isChart" :icon-gray="props.iconGray" />
    <PresentationBadge v-if="isPresentation" :icon-gray="props.iconGray" />
    <MagazineBadge v-if="isMagazine" :icon-gray="props.iconGray" />
  </div>
</template>
